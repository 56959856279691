import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/generic/page-title"

import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"

const ThankYou = () => {
  const data = useStaticQuery(query)
  const employeeOnPhoneImage = data.stockImage.childImageSharp.fluid
  const navigationSlugs = data.navigationSlugs.siteMetadata.navigationSlugs
  return (
    <Layout>
      <SEO
        title="Thank You!"
        description="Thank you for reaching out to us. We'll get back to you ASAP!"
      />
      <PageTitle title="Thank You!" />
      <div className="page-thank-you">
        <div className="page-thank-you__content">
          <div className="page-thank-you__content-text">
            <div className="page-thank-you__heading">
              <h2 className="heading-secondary heading-secondary--heading-underline">
                What's Next?
              </h2>
            </div>
            <p className="paragraph">
              We greatly appreciate you taking the time to fill in our contact
              form.{" "}
              <em>We're confident that you'll be very happy that you did.</em>
            </p>
            <p className="paragraph">
              Moving forward, you can{" "}
              <em>expect to hear from us within 24 hours</em> on weekdays.
              Weekend form submissions are often responded to on the following
              Monday.
            </p>
            <p className="paragraph">
              <em>If you do not hear from us</em> in that timeframe there{" "}
              <em>may have been an error</em> in the form submission.{" "}
              <em>
                Please{" "}
                <Link to={navigationSlugs.find(ns => ns.key === "contact").slug}>
                  contact us
                </Link>
              </em>{" "}
              using the contact page. You can find it in the navigation bar
              above.
            </p>
            <div className="page-thank-you__learn-more-button">
              <Link
                to={navigationSlugs.find(ns => ns.key === "about").slug}
                className="button button--primary button--primary-outline"
              >
                Learn More About Us!
              </Link>
            </div>
          </div>
          <div className="page-thank-you__content-hero-image">
            <Img
              title="Employee on phone."
              alt="A male employee with a big smile on his face wears a headset and a blue polo shirt, preparing to speak on the phone."
              fluid={employeeOnPhoneImage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ThankYou

const query = graphql`
  query {
    stockImage: file(relativePath: { eq: "employee-on-phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    navigationSlugs: site {
      siteMetadata {
        navigationSlugs {
          key
          text
          slug
        }
      }
    }
  }
`
